exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-at-index-tsx": () => import("./../../../src/pages/at/index.tsx" /* webpackChunkName: "component---src-pages-at-index-tsx" */),
  "component---src-pages-at-spectrum-tsx": () => import("./../../../src/pages/at/spectrum.tsx" /* webpackChunkName: "component---src-pages-at-spectrum-tsx" */),
  "component---src-pages-au-index-tsx": () => import("./../../../src/pages/au/index.tsx" /* webpackChunkName: "component---src-pages-au-index-tsx" */),
  "component---src-pages-au-spectrum-tsx": () => import("./../../../src/pages/au/spectrum.tsx" /* webpackChunkName: "component---src-pages-au-spectrum-tsx" */),
  "component---src-pages-bg-arfcn-list-tsx": () => import("./../../../src/pages/bg/arfcn-list.tsx" /* webpackChunkName: "component---src-pages-bg-arfcn-list-tsx" */),
  "component---src-pages-bg-index-tsx": () => import("./../../../src/pages/bg/index.tsx" /* webpackChunkName: "component---src-pages-bg-index-tsx" */),
  "component---src-pages-bg-spectrum-tsx": () => import("./../../../src/pages/bg/spectrum.tsx" /* webpackChunkName: "component---src-pages-bg-spectrum-tsx" */),
  "component---src-pages-de-arfcn-list-tsx": () => import("./../../../src/pages/de/arfcn-list.tsx" /* webpackChunkName: "component---src-pages-de-arfcn-list-tsx" */),
  "component---src-pages-de-index-tsx": () => import("./../../../src/pages/de/index.tsx" /* webpackChunkName: "component---src-pages-de-index-tsx" */),
  "component---src-pages-de-spectrum-tsx": () => import("./../../../src/pages/de/spectrum.tsx" /* webpackChunkName: "component---src-pages-de-spectrum-tsx" */),
  "component---src-pages-dk-arfcn-list-tsx": () => import("./../../../src/pages/dk/arfcn-list.tsx" /* webpackChunkName: "component---src-pages-dk-arfcn-list-tsx" */),
  "component---src-pages-dk-index-tsx": () => import("./../../../src/pages/dk/index.tsx" /* webpackChunkName: "component---src-pages-dk-index-tsx" */),
  "component---src-pages-dk-spectrum-tsx": () => import("./../../../src/pages/dk/spectrum.tsx" /* webpackChunkName: "component---src-pages-dk-spectrum-tsx" */),
  "component---src-pages-ee-index-tsx": () => import("./../../../src/pages/ee/index.tsx" /* webpackChunkName: "component---src-pages-ee-index-tsx" */),
  "component---src-pages-ee-spectrum-tsx": () => import("./../../../src/pages/ee/spectrum.tsx" /* webpackChunkName: "component---src-pages-ee-spectrum-tsx" */),
  "component---src-pages-fi-ax-index-tsx": () => import("./../../../src/pages/fi/ax/index.tsx" /* webpackChunkName: "component---src-pages-fi-ax-index-tsx" */),
  "component---src-pages-fi-ax-spectrum-tsx": () => import("./../../../src/pages/fi/ax/spectrum.tsx" /* webpackChunkName: "component---src-pages-fi-ax-spectrum-tsx" */),
  "component---src-pages-fi-index-tsx": () => import("./../../../src/pages/fi/index.tsx" /* webpackChunkName: "component---src-pages-fi-index-tsx" */),
  "component---src-pages-fi-spectrum-tsx": () => import("./../../../src/pages/fi/spectrum.tsx" /* webpackChunkName: "component---src-pages-fi-spectrum-tsx" */),
  "component---src-pages-gb-arfcn-list-tsx": () => import("./../../../src/pages/gb/arfcn-list.tsx" /* webpackChunkName: "component---src-pages-gb-arfcn-list-tsx" */),
  "component---src-pages-gb-coverage-ee-tsx": () => import("./../../../src/pages/gb/coverage/ee.tsx" /* webpackChunkName: "component---src-pages-gb-coverage-ee-tsx" */),
  "component---src-pages-gb-coverage-index-tsx": () => import("./../../../src/pages/gb/coverage/index.tsx" /* webpackChunkName: "component---src-pages-gb-coverage-index-tsx" */),
  "component---src-pages-gb-coverage-o-2-tsx": () => import("./../../../src/pages/gb/coverage/o2.tsx" /* webpackChunkName: "component---src-pages-gb-coverage-o-2-tsx" */),
  "component---src-pages-gb-coverage-split-screen-tsx": () => import("./../../../src/pages/gb/coverage/split-screen.tsx" /* webpackChunkName: "component---src-pages-gb-coverage-split-screen-tsx" */),
  "component---src-pages-gb-coverage-three-tsx": () => import("./../../../src/pages/gb/coverage/three.tsx" /* webpackChunkName: "component---src-pages-gb-coverage-three-tsx" */),
  "component---src-pages-gb-coverage-vodafone-tsx": () => import("./../../../src/pages/gb/coverage/vodafone.tsx" /* webpackChunkName: "component---src-pages-gb-coverage-vodafone-tsx" */),
  "component---src-pages-gb-index-tsx": () => import("./../../../src/pages/gb/index.tsx" /* webpackChunkName: "component---src-pages-gb-index-tsx" */),
  "component---src-pages-gb-london-underground-connectivity-tsx": () => import("./../../../src/pages/gb/london-underground-connectivity.tsx" /* webpackChunkName: "component---src-pages-gb-london-underground-connectivity-tsx" */),
  "component---src-pages-gb-railway-coverage-notspots-tsx": () => import("./../../../src/pages/gb/railway-coverage-notspots.tsx" /* webpackChunkName: "component---src-pages-gb-railway-coverage-notspots-tsx" */),
  "component---src-pages-gb-road-coverage-notspots-tsx": () => import("./../../../src/pages/gb/road-coverage-notspots.tsx" /* webpackChunkName: "component---src-pages-gb-road-coverage-notspots-tsx" */),
  "component---src-pages-gb-sites-index-tsx": () => import("./../../../src/pages/gb/sites/index.tsx" /* webpackChunkName: "component---src-pages-gb-sites-index-tsx" */),
  "component---src-pages-gb-spectrum-tsx": () => import("./../../../src/pages/gb/spectrum.tsx" /* webpackChunkName: "component---src-pages-gb-spectrum-tsx" */),
  "component---src-pages-gb-three-ran-status-tsx": () => import("./../../../src/pages/gb/three-ran-status.tsx" /* webpackChunkName: "component---src-pages-gb-three-ran-status-tsx" */),
  "component---src-pages-gb-virgin-media-deployment-info-tsx": () => import("./../../../src/pages/gb/virgin-media-deployment-info.tsx" /* webpackChunkName: "component---src-pages-gb-virgin-media-deployment-info-tsx" */),
  "component---src-pages-hu-arfcn-list-tsx": () => import("./../../../src/pages/hu/arfcn-list.tsx" /* webpackChunkName: "component---src-pages-hu-arfcn-list-tsx" */),
  "component---src-pages-hu-index-tsx": () => import("./../../../src/pages/hu/index.tsx" /* webpackChunkName: "component---src-pages-hu-index-tsx" */),
  "component---src-pages-hu-spectrum-tsx": () => import("./../../../src/pages/hu/spectrum.tsx" /* webpackChunkName: "component---src-pages-hu-spectrum-tsx" */),
  "component---src-pages-ie-arfcn-list-tsx": () => import("./../../../src/pages/ie/arfcn-list.tsx" /* webpackChunkName: "component---src-pages-ie-arfcn-list-tsx" */),
  "component---src-pages-ie-index-tsx": () => import("./../../../src/pages/ie/index.tsx" /* webpackChunkName: "component---src-pages-ie-index-tsx" */),
  "component---src-pages-ie-spectrum-tsx": () => import("./../../../src/pages/ie/spectrum.tsx" /* webpackChunkName: "component---src-pages-ie-spectrum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-it-index-tsx": () => import("./../../../src/pages/it/index.tsx" /* webpackChunkName: "component---src-pages-it-index-tsx" */),
  "component---src-pages-it-spectrum-tsx": () => import("./../../../src/pages/it/spectrum.tsx" /* webpackChunkName: "component---src-pages-it-spectrum-tsx" */),
  "component---src-pages-jm-index-tsx": () => import("./../../../src/pages/jm/index.tsx" /* webpackChunkName: "component---src-pages-jm-index-tsx" */),
  "component---src-pages-jm-spectrum-tsx": () => import("./../../../src/pages/jm/spectrum.tsx" /* webpackChunkName: "component---src-pages-jm-spectrum-tsx" */),
  "component---src-pages-kr-index-tsx": () => import("./../../../src/pages/kr/index.tsx" /* webpackChunkName: "component---src-pages-kr-index-tsx" */),
  "component---src-pages-kr-spectrum-tsx": () => import("./../../../src/pages/kr/spectrum.tsx" /* webpackChunkName: "component---src-pages-kr-spectrum-tsx" */),
  "component---src-pages-lt-index-tsx": () => import("./../../../src/pages/lt/index.tsx" /* webpackChunkName: "component---src-pages-lt-index-tsx" */),
  "component---src-pages-lt-spectrum-tsx": () => import("./../../../src/pages/lt/spectrum.tsx" /* webpackChunkName: "component---src-pages-lt-spectrum-tsx" */),
  "component---src-pages-lv-index-tsx": () => import("./../../../src/pages/lv/index.tsx" /* webpackChunkName: "component---src-pages-lv-index-tsx" */),
  "component---src-pages-lv-spectrum-tsx": () => import("./../../../src/pages/lv/spectrum.tsx" /* webpackChunkName: "component---src-pages-lv-spectrum-tsx" */),
  "component---src-pages-maps-dk-mastedatabasen-tsx": () => import("./../../../src/pages/maps/dk-mastedatabasen.tsx" /* webpackChunkName: "component---src-pages-maps-dk-mastedatabasen-tsx" */),
  "component---src-pages-maps-freshwave-tsx": () => import("./../../../src/pages/maps/freshwave.tsx" /* webpackChunkName: "component---src-pages-maps-freshwave-tsx" */),
  "component---src-pages-maps-index-tsx": () => import("./../../../src/pages/maps/index.tsx" /* webpackChunkName: "component---src-pages-maps-index-tsx" */),
  "component---src-pages-maps-streetworks-tsx": () => import("./../../../src/pages/maps/streetworks.tsx" /* webpackChunkName: "component---src-pages-maps-streetworks-tsx" */),
  "component---src-pages-mk-arfcn-list-tsx": () => import("./../../../src/pages/mk/arfcn-list.tsx" /* webpackChunkName: "component---src-pages-mk-arfcn-list-tsx" */),
  "component---src-pages-mk-index-tsx": () => import("./../../../src/pages/mk/index.tsx" /* webpackChunkName: "component---src-pages-mk-index-tsx" */),
  "component---src-pages-mk-spectrum-tsx": () => import("./../../../src/pages/mk/spectrum.tsx" /* webpackChunkName: "component---src-pages-mk-spectrum-tsx" */),
  "component---src-pages-pt-index-tsx": () => import("./../../../src/pages/pt/index.tsx" /* webpackChunkName: "component---src-pages-pt-index-tsx" */),
  "component---src-pages-pt-spectrum-tsx": () => import("./../../../src/pages/pt/spectrum.tsx" /* webpackChunkName: "component---src-pages-pt-spectrum-tsx" */),
  "component---src-pages-ro-arfcn-list-tsx": () => import("./../../../src/pages/ro/arfcn-list.tsx" /* webpackChunkName: "component---src-pages-ro-arfcn-list-tsx" */),
  "component---src-pages-ro-index-tsx": () => import("./../../../src/pages/ro/index.tsx" /* webpackChunkName: "component---src-pages-ro-index-tsx" */),
  "component---src-pages-ro-spectrum-tsx": () => import("./../../../src/pages/ro/spectrum.tsx" /* webpackChunkName: "component---src-pages-ro-spectrum-tsx" */),
  "component---src-pages-rs-arfcn-list-tsx": () => import("./../../../src/pages/rs/arfcn-list.tsx" /* webpackChunkName: "component---src-pages-rs-arfcn-list-tsx" */),
  "component---src-pages-rs-index-tsx": () => import("./../../../src/pages/rs/index.tsx" /* webpackChunkName: "component---src-pages-rs-index-tsx" */),
  "component---src-pages-rs-spectrum-tsx": () => import("./../../../src/pages/rs/spectrum.tsx" /* webpackChunkName: "component---src-pages-rs-spectrum-tsx" */),
  "component---src-pages-ru-index-tsx": () => import("./../../../src/pages/ru/index.tsx" /* webpackChunkName: "component---src-pages-ru-index-tsx" */),
  "component---src-pages-ru-spectrum-tsx": () => import("./../../../src/pages/ru/spectrum.tsx" /* webpackChunkName: "component---src-pages-ru-spectrum-tsx" */),
  "component---src-pages-spectrum-editor-contributing-tsx": () => import("./../../../src/pages/spectrum-editor/contributing.tsx" /* webpackChunkName: "component---src-pages-spectrum-editor-contributing-tsx" */),
  "component---src-pages-spectrum-editor-index-tsx": () => import("./../../../src/pages/spectrum-editor/index.tsx" /* webpackChunkName: "component---src-pages-spectrum-editor-index-tsx" */),
  "component---src-pages-tools-index-tsx": () => import("./../../../src/pages/tools/index.tsx" /* webpackChunkName: "component---src-pages-tools-index-tsx" */),
  "component---src-pages-tools-lte-total-tx-power-calculator-tsx": () => import("./../../../src/pages/tools/lte-total-tx-power-calculator.tsx" /* webpackChunkName: "component---src-pages-tools-lte-total-tx-power-calculator-tsx" */),
  "component---src-pages-tools-nr-nci-to-gnb-sector-id-tsx": () => import("./../../../src/pages/tools/nr-nci-to-gnb-sector-id.tsx" /* webpackChunkName: "component---src-pages-tools-nr-nci-to-gnb-sector-id-tsx" */),
  "component---src-pages-tt-index-tsx": () => import("./../../../src/pages/tt/index.tsx" /* webpackChunkName: "component---src-pages-tt-index-tsx" */),
  "component---src-pages-tt-spectrum-tsx": () => import("./../../../src/pages/tt/spectrum.tsx" /* webpackChunkName: "component---src-pages-tt-spectrum-tsx" */),
  "component---src-pages-ua-arfcn-list-tsx": () => import("./../../../src/pages/ua/arfcn-list.tsx" /* webpackChunkName: "component---src-pages-ua-arfcn-list-tsx" */),
  "component---src-pages-ua-index-tsx": () => import("./../../../src/pages/ua/index.tsx" /* webpackChunkName: "component---src-pages-ua-index-tsx" */),
  "component---src-pages-ua-spectrum-tsx": () => import("./../../../src/pages/ua/spectrum.tsx" /* webpackChunkName: "component---src-pages-ua-spectrum-tsx" */),
  "component---src-templates-blog-article-blog-articles-list-tsx": () => import("./../../../src/templates/blog-article/BlogArticlesList.tsx" /* webpackChunkName: "component---src-templates-blog-article-blog-articles-list-tsx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-07-18-welcome-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2021/07/18/welcome.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-07-18-welcome-mdx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-07-20-introduction-to-4-g-and-lte-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2021/07/20/introduction-to-4g-and-lte.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-07-20-introduction-to-4-g-and-lte-mdx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-07-24-lte-bands-and-duplex-modes-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2021/07/24/lte-bands-and-duplex-modes.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-07-24-lte-bands-and-duplex-modes-mdx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-08-04-mast-sectorisation-radiation-and-deployment-patterns-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2021/08/04/mast-sectorisation-radiation-and-deployment-patterns.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-08-04-mast-sectorisation-radiation-and-deployment-patterns-mdx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-08-21-ofdm-in-4-g-5-g-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2021/08/21/ofdm-in-4g-5g.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-08-21-ofdm-in-4-g-5-g-mdx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-10-11-speed-differences-high-low-frequencies-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2021/10/11/speed-differences-high-low-frequencies.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-10-11-speed-differences-high-low-frequencies-mdx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-12-22-lte-mimo-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2021/12/22/lte-mimo.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2021-12-22-lte-mimo-mdx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2022-01-24-o-2-orion-monopoles-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2022/01/24/o2-orion-monopoles.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2022-01-24-o-2-orion-monopoles-mdx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2022-04-ee-nr-ca-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2022/04/ee-nr-ca.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2022-04-ee-nr-ca-mdx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2023-09-vodafone-three-merger-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2023/09/vodafone-three-merger.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2023-09-vodafone-three-merger-mdx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2024-02-curious-case-vodafone-5-g-standalone-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2024/02/curious-case-vodafone-5g-standalone.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2024-02-curious-case-vodafone-5-g-standalone-mdx" */),
  "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2024-03-o-2-capacity-management-mdx": () => import("./../../../src/templates/blog-article/BlogPageTemplate.jsx?__contentFilePath=/home/runner/work/mastdatabase.co.uk/mastdatabase.co.uk/blog/2024/03/o2-capacity-management.mdx" /* webpackChunkName: "component---src-templates-blog-article-blog-page-template-jsx-content-file-path-blog-2024-03-o-2-capacity-management-mdx" */)
}

